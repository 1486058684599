/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Sigo con mis frikadas de Star Wars en este caso es una de las mejores series que he leido, se llama ", React.createElement(_components.a, {
    href: "http://www.megaupload.com/es/?d=54T06CK7"
  }, "Relatos de los Jedi"), ", situada en la antigua republica 4000 años antes de La Amenaza Fantasma, trata de los ultimos dias del Imperio Sith y su resurgimiento bajo Exar Kun."), "\n", React.createElement(_components.p, null, "Un saludo."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
